.App {
  /* text-align: center; */
}
.dashboard-container {
  padding: 15px;
  width: 100%;
  height: 100%;
  transition: all 1s ease;
}
.main-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 1s ease;
}
.topbar-container {
  flex: 1;
  width: 100vw;
}

.center-container {
  flex: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.leftsidebar-container {
  flex: 1;
}

.content-container {
  flex: 8;
}

.data-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-column-image {
  height: 100px;
  width: 100px;
  object-fit: contain;
}
.order-dash-container {
  display: flex;
  height: 100%;
  width: 100%;
  align-self: center;
  justify-self: center;
}
.order-dash-left-item {
  flex: 1;
  height: 100%;
  background-color: coral;
}
.order-dash-center-item {
  flex: 3;
  height: 100%;
  background-color: aquamarine;
}

.order-dash-right-item {
  flex: 1.5;
  height: 100%;
  background-color: cadetblue;
}
